<template>
  <section>

    <total-widget :entities="tasks"/>
    <v-data-table :headers="headers.filter(header => header.visibleFor.includes(role) || role === 'admin' || 'agent')"
                  :items="tasks.data"
                  :items-per-page="10"
                  flat
                  hide-default-footer>
      <!-- Details -->
      <template v-slot:item.details="{item}">
      <task-table-name-display-widget :task="item" />
      </template>
      <!-- Due Date -->
      <template v-slot:item.due="{item}">
        <h3 class="mb-0 text-xl-h7">{{ item.due_date | moment("DD MMM YY") }}</h3>
        <p class="ma-0"><small>{{ item.due_diff_human }}</small></p>
      </template>
      <!-- Reporter -->

      <!-- Assignee -->
      <template v-slot:item.assignee="{item}">
        <div v-if="item.assignee">
          <h1 class="mb-0 text-xl-h6">{{ item.assignee.full_name }}</h1>
          <p class="ma-0"><small>{{ item.assignee.role_name }}</small></p>
        </div>
        <div v-if="!item.assignee">
          <h1 class="mb-0 text-xl-h6">Unassigned</h1>
        </div>
      </template>
      <!-- Customer -->
      <template v-slot:item.customer="{item}">

        <h3 v-if="item.customer" class="mb-0 text-xl-h6">{{ item.customer.full_name }}</h3>


      </template>
      <!-- Status -->
      <template v-slot:item.status="{item}">
        <div class="mb-5 mb-sm-0">
          <task-status-display :task="item"/>
        </div>
      </template>
      <template v-slot:item.controls="{item}">
        <v-btn class="hidden-xs-only" @click="viewTask(item)">View</v-btn>
      </template>
    </v-data-table>

    <v-pagination
        v-model="page"
        :length="tasks.last_page"
        :total-visible="6"
        @input="paginationChange"
    ></v-pagination>

  </section>
</template>
<script>
import tasks from "../../../../../api/modules/tasks";
import {EventBus} from "../../../../../bootstrap/EventBus";
import TaskStatusDisplay from "../../show/components/TaskStatusDisplay";
import {mapGetters} from 'vuex';
import TotalWidget from "@/views/components/TotalWidget.vue";
import TaskTableNameDisplayWidget from "@/views/components/TaskTableNameDisplayWidget.vue";

export default {
  name: 'TasksTable',
  components: {TaskTableNameDisplayWidget, TotalWidget, TaskStatusDisplay},
  props: ['defaults'],
  data: () => ({
    page: 1,
    headers: [
      {text: 'Details', value: 'details', visibleFor: ['nurse', 'recruiter']},
      {text: 'Due', value: 'due', visibleFor: ['nurse', 'recruiter']},
      {text: 'Assignee', value: 'assignee', visibleFor: ['admin']},
      {text: 'Client', value: 'customer', visibleFor: ['admin']},
      {text: 'Status', value: 'status', visibleFor: ['nurse', 'recruiter']},
      {text: 'Controls', value: 'controls', visibleFor: ['nurse', 'recruiter']},
    ],
    tasks: {
      data: []
    },
    filters: {},
  }),
  created() {
    this.filters = this.defaults;

    this.fetchTasks();

    EventBus.$on('tasks-filters', filters => {
      this.page = 1;
      if (filters) {
        this.filters = filters;
      }
      this.fetchTasks();
    })
  },
  methods: {
    fetchTasks: function () {
      tasks.fetchTasks(this.filters).then(res => {
        this.tasks = res.data;
      })
    },
    viewTask: function (task) {
      EventBus.$emit('task-show-dialog', task);
    },
    paginationChange: function () {
      this.filters.page = this.page;
      this.fetchTasks();
    },
  },
  beforeDestroy() {
    // EventBus.$off('tasks-filters');
  },
  computed: {
    ...mapGetters('auth', ['role']),
  }
}
</script>
