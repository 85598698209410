<template>
  <div>
    <v-card>
      <v-card-title>
        Caretaker Fee
      </v-card-title>
      <v-card-subtitle>
        View, Manage <code>Invoices</code>
      </v-card-subtitle>
      <v-card-text>
        <v-sheet class="d-flex mt-4">
          <v-sheet max-width="250px" class="mr-5">
            <select-caretaker-dropdown v-model="filters.user_id" @input="filter()" />
          </v-sheet>
          <v-btn class="ml-auto hidden-xs-only" @click="resetFilters">Clear</v-btn>
        </v-sheet>
        <v-sheet>
          <v-data-table
            :headers="headers.filter(header => header.visibleFor === role || role === 'admin')" flat
            :items="invoices.data"
            :items-per-page="10" hide-default-footer>
            <template v-slot:item.date_of_turn="{item}">
              {{ item.start_date }} - {{ item.end_date }}
            </template>

            <template v-slot:item.customer="{item}">
              <div v-if="item.customer">
                <h1 class="mb-0 text-xl-h6">{{item.customer.full_name}}</h1>
                <p class="ma-0"><small>{{item.customer.email}}</small></p>
              </div>
              <div v-else>
                <v-chip label small>None</v-chip>
              </div>
            </template>

            <template v-slot:item.agent="{item}">
              <div v-if="item.agent">
                <h1 class="mb-0 text-xl-h6">{{item.agent.full_name}}</h1>
                <p class="ma-0"><small>{{item.agent.email}}</small></p>
              </div>
              <div v-else>
                <v-chip label small>None</v-chip>
              </div>
            </template>

            <template v-slot:item.recruiter="{item}">
              <div v-if="item.recruiter">
                <h1 class="mb-0 mt-2 text-xl-h6">
                  {{item.recruiter.full_name}}
                </h1>
                <p class="head-m-0"><small>{{item.recruiter.email}}</small></p>
              </div>
              <div v-else>
                <v-chip label small>None</v-chip>
              </div>
            </template>

            <template v-slot:item.caretaker="{item}">
              <div v-if="item.receiver">
                <h1 class="mb-0 text-xl-h6">{{item.receiver.full_name}}</h1>
                <p class="ma-0"><small>{{item.receiver.email}}</small></p>
              </div>
              <div v-else>
                <v-chip label small>None</v-chip>
              </div>
            </template>

            <template v-slot:item.amount="{item}">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-bind="attrs"
                          v-on="on"
                          :color="item.amount > 0 ? 'success' : 'warning'">
                    {{item.amount}} €
                  </v-chip>
                </template>
                <span>
                        {{item.name}}
                    </span>
              </v-tooltip>
            </template>
            <template v-slot:item.status="{item}">
              <v-chip v-if="item.status === 1">Pending</v-chip>
              <v-chip v-if="item.status === 2" color="success">Paid</v-chip>
            </template>
            <template v-slot:item.controls="{item}">
              <div class="d-flex">
                <v-btn :disabled="item.status !== 1" @click="showCompleteInvoice(item)" icon>
                  <v-icon>mdi-check</v-icon>
                </v-btn>
                <a target="_blank" :href="getAttachmentDownloadLink(item)" download>
                  <v-btn :disabled="!item.file" icon>
                    <v-icon>mdi-download-outline</v-icon>
                  </v-btn>
                </a>
              </div>
            </template>
          </v-data-table>
          <v-pagination
              v-model="page"
              @input="paginationChange"
              :length="filters.last_page"
              :total-visible="6"
          ></v-pagination>
        </v-sheet>
      </v-card-text>
    </v-card>
    <complete-popup />
  </div>
</template>

<script>
import SelectCaretakerDropdown from "@/components/forms/SelectCaretakerDropdown";

import invoices from "@/api/modules/invoices";
import {mapGetters} from "vuex";
import users from "@/api/modules/users";
import CompletePopup from "./CompletePopup";
import {EventBus} from "@/bootstrap/EventBus";


export default {
  name: "CaretakerFee",
  components: {SelectCaretakerDropdown, CompletePopup},
  props: ['defaults'],
  data() {
    return {
      caretakers: [],
      filters: {
        type: 2,
        user_id: null,
        page: 1,
        last_page: null,
        per_page: 10
      },
      page: 1,
      headers: [
        {text: 'Invoice Number', value: 'number', visibleFor: 'admin', sortable: false},
        {text: 'Date', value: 'created_at', visibleFor: 'admin', sortable: false},
        {text: 'Invoice Number', value: 'id', visibleFor: 'admin', sortable: false},
        {text: 'Date of Turn', value: 'date_of_turn', visibleFor: 'admin', sortable: false},
        {text: 'Amount of Days', value: 'days_amount', visibleFor: 'admin', sortable: false},
        {text: 'Recruiter', value: 'recruiter', visibleFor: 'admin', sortable: false},
        {text: 'Caretaker', value: 'caretaker', visibleFor: 'admin', sortable: false},
        {text: 'Customer', value: 'customer', visibleFor: 'admin', sortable: false},
        {text: 'Agent', value: 'agent', visibleFor: 'admin', sortable: false},
        {text: 'Amount', value: 'amount', visibleFor: 'admin', sortable: false},
        {text: 'Status', value: 'status', visibleFor: 'admin', sortable: false},
        {text: 'Date Paid', value: 'date_paid', visibleFor: 'admin', sortable: false},
        {text: 'Controls', value: 'controls', visibleFor: 'admin', sortable: false},
      ],
      invoices: [],
    }
  },
  created: function () {
    this.resetFilters()
    this.fetchInvoices()
    users.fetchAssigneesDictionary().then(res => {
      this.caretakers = res.data;
    })
    EventBus.$on('fetch-invoices', () => {
      this.fetchInvoices()
    })
  },
  computed: {
    ...mapGetters('auth', ['role']),
  },
  methods: {
    fetchInvoices: function () {
      invoices.fetchInvoices(this.filters).then(res => {
        this.invoices = res.data;
        this.filters.last_page = res.data.meta.last_page
      })
    },
    paginationChange: function () {
      this.filters.page = this.page;
      this.fetchInvoices();
    },
    filter: function () {
      this.fetchInvoices()
    },
    resetFilters: function () {
      this.filters = {
        caretaker_id: null,
        page: 1,
        type: 2,
        last_page: null,
        per_page: 10,
        ...this.defaults
      };

      this.fetchInvoices()
    },
    showCompleteInvoice(invoice) {
      EventBus.$emit('complete-invoice-popup', invoice)
    },
    getAttachmentDownloadLink: function (invoice) {
      return invoice?.file?.full_path
    }
  },
}
</script>