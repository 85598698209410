<template>
  <div>
    <v-card>
      <v-card-title>
        Other Invoices
      </v-card-title>
      <v-card-subtitle>
        View, Manage <code>Invoices</code>
      </v-card-subtitle>
      <v-card-text>
        <v-sheet class="d-flex mt-4">
          <v-sheet max-width="250px" class="mr-5">
            <select-assignee-dropdown @input="filter()" label="Assignee" v-model="filters.user_id"/>
          </v-sheet>
          <v-sheet max-width="250px" class="mr-5">
            <select-customer-dropdown @input="filter()" v-model="filters.customer_id"/>
          </v-sheet>
          <v-sheet max-width="250px" class="mr-5">
            <select-assignee-dropdown @input="filter()" label="Approved By" v-model="filters.approver_id"/>
          </v-sheet>
          <v-btn class="ml-auto hidden-xs-only" @click="resetFilters">Clear</v-btn>
        </v-sheet>
        <v-sheet>
          <v-data-table
              :headers="headers.filter(header => header.visibleFor === role || role === 'admin')" flat
              :items="invoices.data"
              :items-per-page="10" hide-default-footer>
            <!-- Details -->
            <template v-slot:item.receiver="{item}">
              <div v-if="item.receiver">
                <h1 class="mb-0 mt-2 text-xl-h6">
                  {{item.receiver.full_name}}
                </h1>
                <p class="head-m-0"><small>{{item.receiver.email}}</small></p>
                <v-chip v-if="item.receiver_type" label x-small class="text-capitalize mb-3" color="primary">{{item.receiver_type.slice(0, -1)}}
                </v-chip>
              </div>
              <div v-else>
                <v-chip label small>None</v-chip>
              </div>
            </template>

            <template v-slot:item.approved_by="{item}">
              <div v-if="item.approved_by">
                <h1 class="mb-0 text-xl-h6">{{item.approved_by.full_name}}</h1>
                <p class="ma-0"><small>{{item.approved_by.email}}</small></p>
              </div>
              <div v-else>
                <v-chip label small>None</v-chip>
              </div>
            </template>

            <template v-slot:item.amount="{item}">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-bind="attrs"
                          v-on="on"
                          :color="item.amount > 0 ? 'success' : 'warning'">
                    {{item.amount}} €
                  </v-chip>
                </template>
                <span>
                        {{item.name}}
                    </span>
              </v-tooltip>
            </template>

            <template v-slot:item.created_at="{item}">
              <div class="my-sm-6 my-3">{{item.created_at | moment("DD MMM YY") }}</div>
            </template>

            <template v-slot:item.status="{item}">
              <v-chip v-if="item.status === 1">Pending</v-chip>
              <v-chip v-if="item.status === 2" color="success">Paid</v-chip>
            </template>

            <template v-slot:item.controls="{item}">
              <v-btn :disabled="item.status !== 1" @click="showCompleteInvoice(item)" icon>
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <v-pagination
              v-model="page"
              @input="paginationChange"
              :length="filters.last_page"
              :total-visible="6"
          ></v-pagination>
        </v-sheet>
      </v-card-text>
    </v-card>
    <complete-popup />
  </div>
</template>

<script>
import SelectAssigneeDropdown from "@/components/forms/SelectAssigneeDropdown.vue";
import SelectCustomerDropdown from "@/components/forms/SelectCustomerDropdown.vue";
import invoices from "@/api/modules/invoices";
import {mapGetters} from "vuex";
import {EventBus} from "@/bootstrap/EventBus";
import CompletePopup from "./CompletePopup";

export default {
  name: "CaretakerFee",
  props: ['defaults'],
  components: {SelectAssigneeDropdown, SelectCustomerDropdown, CompletePopup},
  data() {
    return {
      filters: {
        type: 3,
        user_id: null,
        approver_id: null,
        customer_id: null,
        page: 1,
        last_page: null
      },
      page: 1,
      headers: [
        {text: 'Invoice Number', value: 'number', visibleFor: 'admin', sortable: false},
        {text: 'Receiver', value: 'receiver', visibleFor: 'admin', sortable: false},
        {text: 'Approved By', value: 'approved_by', visibleFor: 'admin', sortable: false},
        {text: 'Amount', value: 'amount', visibleFor: 'admin', sortable: false},
        {text: 'Date', value: 'created_at', visibleFor: 'admin', sortable: false},
        {text: 'Status', value: 'status', visibleFor: 'admin', sortable: false},
        {text: 'Controls', value: 'controls', visibleFor: 'admin', sortable: false},
      ],
      invoices: [],
    }
  },
  created: function () {
    this.resetFilters()

    this.fetchInvoices()
    EventBus.$on('fetch-invoices', () => {
      this.fetchInvoices()
    })
  },
  computed: {
    ...mapGetters('auth', ['role']),
  },
  methods: {
    fetchInvoices: function () {
      invoices.fetchInvoices(this.filters).then(res => {
        this.invoices = res.data;
        this.filters.last_page = res.data.meta.last_page
      })
    },
    paginationChange: function () {
      this.filters.page = this.page;
      this.fetchInvoices();
    },
    filter: function () {
      this.fetchInvoices()
    },
    resetFilters: function () {
      this.filters = {
        type: 3,
        user_id: null,
        approver_id: null,
        customer_id: null,
        page: 1,
        last_page: null,
        ...this.defaults
      };

      this.fetchInvoices()
    },
    showCompleteInvoice(invoice) {
      EventBus.$emit('complete-invoice-popup', invoice)
    }
  },
}
</script>